@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


.point {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
}

.status-point-text {
  position: relative;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  font-size: 0.75rem;
  line-height: 1rem;
  left: 10px;
  width: fit-content;
  min-width: 5rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
  display:none;
}

.point:hover .status-point-text {
  display: block;
}

.mapboxgl-canvas {
  z-index: 3;
}

.mapboxgl-marker {
  z-index: 4;
}

.pdf-viewer-container .rpv-core__viewer {
  height: 100%;
}

.pdf-viewer-container .rpv-core__page-layer {
  height: 100% !important;
  width: 100% !important;
}

.pdf-viewer-container canvas {
  width: 100% !important;
  height: 100% !important;
}
